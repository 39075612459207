import React from "react";
import Banner from "../Banner/Banner";
import {Avatar, Box, Button, Container, Grid, Hidden, styled, Typography, useTheme} from "@mui/material";
import StaffDetails from "../StaffDetails/StaffDetails";
import {Link} from "react-router-dom";

const TeamBannerStyled = styled("div")(({theme}) => ({
    [".team-banner-blason"]: {
        width: "120px"
    },
    [".team-banner-name"]: {
        fontSize: "1.5rem"
    },
    [".team-banner-staff"]: {
        position: "relative",
        height: '100%',
        border: "black 2px solid",
        borderRadius: theme.shape.borderRadius,
        zIndex: 1
    },
    [".team-banner-staff-bg"]: {
        position: "absolute",
        top: "0px",
        left: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.5)',
        zIndex: "0",
        borderRadius: theme.shape.borderRadius

    },
    [theme.breakpoints.down("sm")]: {
        [".team-banner-blason"]: {
            width: "100px"
        },
        [".team-banner-name"]: {
            fontSize: "1.2rem"
        },
    }
}))

export default function TeamBanner(props) {
    const {components} = useTheme();
    const {team} = props;

    return (
        <TeamBannerStyled>
            {team &&
                <Banner imgSource={components.bbsa.teamBanner.image} bgClassName={"bbsa-team-banner-bg-filter"}>
                    {team.costlyError &&
                        <Box sx={{position: "absolute", bottom: 5, left: 10}}>
                            <div className="screenAlert-icon screenAlert-warning scaleWarning">
                                <span className="screenAlert-body pulseWarningIns"></span>
                                <span className="screenAlert-dot pulseWarningIns"></span>
                            </div>
                            <Typography>
                                Erreur couteuse
                            </Typography>
                        </Box>
                    }

                    <Typography sx={{position: "absolute", top: 5, right: 10}}>
                        <Link to={"/teams/" + team.id + "/sheet"} target={"_blank"}>Feuille de match</Link>
                    </Typography>
                    <Box>
                        <Typography sx={{position: "absolute", bottom: 5, right: 10}}>coach : {team.coach}</Typography>
                        <Typography sx={{position: "absolute", top: 5, left: 10}}>roster : {team.race.name}</Typography>
                    </Box>
                    <Typography>

                    </Typography>
                    <Container maxWidth={"lg"}>
                        <div align={"center"}>
                            <Avatar
                                alt={team.name}
                                src={team.blasonUrl}
                                sx={{width: 120, height: 120}}
                            />
                        </div>
                        <Typography align={"center"} className={"team-banner-name"}>{team.name}</Typography>
                    </Container>
                </Banner>
            }
        </TeamBannerStyled>
    )
}

